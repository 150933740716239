import { PageProps, graphql } from 'gatsby'
import React, { useMemo } from 'react'
import RepairContent, { GridItem } from '../components/RepairContent'
import { repairOtherBrandPageBuilder } from './RepairOtherBrandPage.context'
import { LayoutProps } from '../components/Layout.context'
import { Helmet } from 'react-helmet'
import { RepairPageContext } from './ReparationPage.context'
import { SaveQuoteBrandsQuery } from '../_generated/codegen/graphqlTypes'
import { Unpacked } from '../utils/Unpacked'
import {getProductByUrlCode} from '../data/productTypes'

const pathBuilder = (item: GridItem, productType: string) =>
  repairOtherBrandPageBuilder(productType, item.name ?? '')
const useItem = (data: SaveQuoteBrandsQuery): ISaveBrand[] => {
  return useMemo(() => {
    return data.allSaveQuoteBrand.nodes ?? []
  }, [data])
}
type ISaveBrand = NonNullable<
  Unpacked<SaveQuoteBrandsQuery['allSaveQuoteBrand']['nodes']>
>
const ContactPage: React.FC<
  PageProps<SaveQuoteBrandsQuery, RepairPageContext>
> = ({ data, pageContext }) => {
  const brands = useItem(data)
  return (
    <>
      <Helmet>
        <title>{"Votre devis pour réparer votre "+getProductByUrlCode(pageContext.urlCode)?.label.toLocaleLowerCase()+" en 2 minutes chrono !"}</title>
        <meta
          name="description"
          content={"Obtenez le prix de la réparation de votre "+getProductByUrlCode(pageContext.urlCode)?.label.toLocaleLowerCase()+" en quelques clics - Réparation Garantie 1 an - Toutes marques  - 300 magasins en Europe."}
        />
      </Helmet>

      <RepairContent>
        <RepairContent.Card
          index={1}
          title={
            <RepairContent.SeoHeader
              title={`Réparation de ${getProductByUrlCode(
                pageContext.urlCode
              )?.arianeLabel.toLocaleLowerCase()}`}
              subtitle={`Choisir la marque de ${getProductByUrlCode(
                pageContext.urlCode
              )?.arianeLabel.toLocaleLowerCase()}`}
            />
          }
        >
          <RepairContent.Grid
            items={brands}
            pathBuilder={(item) => pathBuilder(item, pageContext.urlCode)}
          />
        </RepairContent.Card>

        <RepairContent.Cgu>
          <h3>LA RÉPARATION DE SMARTPHONE PRÈS DE CHEZ VOUS AVEC SAVE</h3>
          <p>
            Pour la réparation de votre smartphone à Nantes, à Paris, à Lyon ou
            encore à Marseille, avec Save, il est possible d’accéder à l’un de
            nos 200 magasins disséminés partout en France. Toujours plus proches
            de vous, nous remettons en état vos appareils en respectant aussi
            bien les exigences de la profession que celles des constructeurs.
          </p>
          <p>
            Grâce à des composants originaux et à une prise en charge
            d’exception, Save est l’interlocuteur idéal pour réparer votre
            téléphone en toute quiétude. Et le plus séduisant réside dans le
            fait que nous vous proposons un large éventail de prestations pour
            des prix défiant toute concurrence.
          </p>
          <p>
            Ainsi, vous n’allez pas inutilement jeter votre smartphone pour en
            acheter un autre et y mettre vos économies. Avec nos qualifications
            expertes, vous n’éprouverez aucune difficulté à faire réparer votre
            téléphone.
          </p>

          <h3>UNE PRISE EN CHARGE RAPIDE POUR RETROUVER LE SOURIRE</h3>
          <p>
            Chez Save, nous mettons un point d’honneur à satisfaire nos clients
            en leur proposant les meilleures prestations dans des délais très
            courts. En effet, nous avons pris l’engagement de réparer votre
            téléphone en 40 minutes seulement. Une exigence que nous respectons
            dans 82% des cas.
          </p>
          <p>
            Par contre, pour des pannes plus complexes, l’immobilisation de
            votre smartphone sera nécessaire. Dans ce cas, nous n’hésiterons pas
            à vous prêter un téléphone le temps que le dépannage soit effectué
            (sous réserve de stock disponible). Ainsi, vous pourrez recevoir vos
            appels et vos messages sans risque de rater une communication
            importante.
          </p>
          <p>
            En vous rendant dans l’un de nos 200 magasins, vous profiterez d’un
            accueil chaleureux et par la même occasion d’une prise en charge
            personnalisée. Dotées d’une grande capacité d’écoute, nos équipes de
            techniciens experts réaliseront un diagnostic complet de vos pannes.
          </p>
          <p>
            Une démarche qui vous permettra de profiter d’un devis sur mesure.
            De cette façon, vous connaîtrez le montant exact à payer, ce qui
            vous évitera toutes mauvaises surprises. En toute transparence,
            votre technicien vous expliquera les contours de la panne et vous
            exposera la solution la mieux adaptée à vos besoins.
          </p>

          <h3>UNE TARIFICATION MILLIMÉTRÉE EN TOUTE TRANSPARENCE</h3>
          <p>
            Faire réparer son téléphone peut parfois coûter très cher. Surtout
            lorsque celui-ci n’est plus sous garantie et que vous devez le
            rapporter auprès de votre SAV. Pour économiser sereinement, Point
            Service Mobiles vous propose des devis en toute transparence à des
            prix étudiés.
          </p>
          <p>
            En fonction de la marque (Samsung, Honor, Sony, Apple, etc.) et du
            modèle (Galaxy Note 9, iPhone XR, Huawei P20, Xperia Z5, etc.) de
            votre smartphone, nous vous proposons un devis millimétré. Pour vous
            simplifier la vie, nos prestations comprennent aussi bien le coût de
            la main-d’œuvre que celui du prix la pièce de rechange.
          </p>
          <p>
            Avec Save tout est fait pour ne pas dépenser plus qu’il ne faut.
            Toujours dans l’optique de vous faire économiser, nous mettons
            fréquemment à votre disposition des codes promo pour profiter de
            belles réductions. Soyez donc vigilant pour ne pas rater la vôtre !
          </p>

          <h3>UNE EXPERTISE D’EXCEPTION AU SERVICE DE CHACUN DE VOS BESOINS</h3>
          <p>
            La qualité de nos prestations, la diligence des réparations et le
            dynamisme de nos équipes sont entre autres les principaux atouts qui
            ont permis à Save de s’imposer comme le leader de la réparation de
            smartphone en France.
          </p>
          <p>
            Au quotidien, nous prenons plaisir à redonner le sourire à nos
            clients en répondant promptement à leurs besoins tout en proposant
            une tarification de choix. Nos techniciens reçoivent des formations
            en interne et bénéficient de mises à niveau pour renforcer leurs
            capacités opérationnelles.
          </p>
          <p>
            De telles exigences nous permettent de toujours répondre aux désirs,
            même les plus complexes, de notre aimable clientèle. Que ce soit
            pour la réparation de l'écran d’un iPhone XS, le remplacement de la
            batterie d’un Samsung Galaxy S9+ ou encore le changement de la
            caméra arrière d’un Huawei Mate 10 Pro, vous trouverez chez Save, la
            satisfaction en tout point.
          </p>

          <h3>
            DES PIÈCES DE RECHANGE D’ORIGINE POUR OFFRIR UNE SECONDE VIE À VOTRE
            TÉLÉPHONE
          </h3>
          <p>
            Connecteur de charge défaillant, touches de volume hors service,
            téléphone oxydé après une chute dans la piscine, micro qui ne répond
            plus, etc., les pannes sont aussi diverses que variées. Se rendre
            dans l’une de vos boutiques vous permettra de remédier facilement à
            votre problème.
          </p>
          <p>
            Contrairement à un service SAV classique où il faudra dépenser une
            petite fortune pour ensuite être soumis à un délai de réparation
            horriblement long, Save vous propose une solution moins onéreuse,
            mais tout aussi avantageuse.
          </p>
          <p>
            En plus de l’expertise remarquable de nos réparateurs, vous
            profiterez de pièces d’origine pour chacune de vos réparations.
            Notre mission est d’offrir une seconde vie à votre smartphone, c’est
            pourquoi nos pièces de rechange sont les mêmes que les constructeurs
            utilisent. Mieux encore, toutes nos réparations sont garanties un an
            !
          </p>
          <p>
            Ainsi, une fois remis en état, vous n’aurez pas à craindre que votre
            smartphone retombe en panne. Avec les pièces d’origine, votre
            smartphone n’aura rien à envier à un modèle sorti d’usine. HTC,
            Samsung, Nokia, Wkio ou encore Doro, notre large catalogue de pièces
            détachées vous permettra de bénéficier d’une réparation durable et
            fiable.
          </p>
        </RepairContent.Cgu>
      </RepairContent>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Votre devis pour réparer votre smartphone en 2 minutes chrono !',
  repairStepsContainerProps: {
    show: true,
    showHero: true,
  },
}

export const query = graphql`
  query SaveQuoteBrands($productType: String) {
    allSaveQuoteBrand(filter: { id: { regex: $productType } }) {
      nodes {
        id
        imgUrl
        name
        imageFile {
          publicURL
        }
      }
    }
  }
`
export default Object.assign(ContactPage, {
  layoutProps,
})
